<template>
  <div class="popup_layer">
    <div class="inner_popup_layer" style="width: 584px">
      <div class="layer_head">
        <strong class="tit_popup">프로젝트 코드 조회</strong>
      </div>
      <div class="layer_body">
        <PopProjectCodeSearchFilter @onClickSearch="onClickSearch" />
        <div class="tbl_comm tbl_list tbl_scroll">
          <div
            class="inner_scroll"
            style="height: calc(100vh - 544px); max-height: 294px; min-height: 49px"
          >
            <table>
              <colgroup>
                <col style="width: 52px" />
                <col style="width: 164px" />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th style="width: 52px" />
                  <th style="width: 164px">프로젝트코드</th>
                  <th style="width: 304px">사업명</th>
                </tr>
              </thead>
              <tbody>
                <PopProjectCodeListLine
                  v-for="item in projectDataList"
                  :key="item.projectCode"
                  :rowData="item"
                  :selectedProject.sync="selectedProjectSync"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="layer_foot">
        <div class="wrap_btn">
          <button
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="$emit('onClickClose')"
          >
            취소
          </button>
          <button
            type="button"
            class="btn_medium btn_medium btn_secondary"
            :disabled="!selectedProjectSync.projectCode"
            @click.prevent="$emit('onClickComplete', selectedProjectSync)"
          >
            적용
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PopProjectCodeSearchFilter from "./PopProjectCodeSearchFilter";
import PopProjectCodeListLine from "./PopProjectCodeListLine";
import ApiService from "@/services/ApiService";

export default {
  name: "PopProjectCodeSearch",
  components: {
    PopProjectCodeSearchFilter,
    PopProjectCodeListLine,
  },
  props: {
    selectedProject: Object,
  },
  data() {
    return {
      projectDataList: [],
      selectedProjectSync: {},
    };
  },
  mounted() {
    this.selectedProjectSync = this.selectedProject;
    this.getDataList();
  },
  methods: {
    async getDataList(projectName) {
      let path = "";
      if (projectName) {
        path = `${this.$apiPath.PROJECT_CODE}?projectName=${projectName}`;
      } else {
        path = `${this.$apiPath.PROJECT_CODE}`;
      }

      const res = await ApiService.shared.getData(path);

      if (res.data) {
        this.projectDataList = res.data;
      }
    },
    onClickSearch(searchKeyword) {
      this.getDataList(searchKeyword);
    },
  },
};
</script>
